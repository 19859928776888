import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/ddt/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/ddt/get_all?token="+token).then(response => {
            return response.data;
        });
    },
    async getOne(_id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/ddt/get_one?_id="+_id).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/ddt/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/ddt/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async sendFileFirma(token, id_ddt, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/ddt/send_file_firma?token="+token+"&id_ddt="+id_ddt, params).then(response => {
            return response.data;
        });
    },
}
<template>
  <div class="newDDT" style="height:100%;">
    <v-toolbar color="secondary" dense>
        <v-toolbar-title class="white--text">
            DDT
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon @click="remove_ddt()" color="white">
          <v-icon small>fas fa-trash</v-icon>
        </v-btn>
        <v-btn small icon @click="isEditing = !isEditing" color="white" class="ml-3">
          <v-icon small>fas fa-edit</v-icon>
        </v-btn>
        <v-btn small icon @click="$emit('DDTdone')" color="white" class="ml-3 mr-1">
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
    </v-toolbar>
    <v-card tile class="px-2">
        <v-form v-model="formValid" ref="form_data" lazy-formValidation>
          <v-tabs
            v-model="tab"
            class="pl-3 pt-2"
          >
            <!--v-tabs-slider color="yellow"></v-tabs-slider-->
            <v-tab>Testa</v-tab>
            <v-tab>Corpo</v-tab>
            <v-tab>Calce</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="mt-3">
            <v-tab-item>
              <v-container>
                <v-layout column>
                    <v-select
                        dense
                        :readonly="!isEditing"
                        :items="['Baggi', 'LaCelle']"
                        v-model="dettagli_testa"
                        required
                        outlined
                        hide-details="auto"
                        :rules="requiredRules"
                        label="Dettagli Testa"
                        class="mb-5"
                    ></v-select>
                    <v-text-field
                        autocomplete="off"
                        :readonly="!isEditing"
                        label="Nominativo Destinatario"
                        v-model="nome_destinatario"
                        class="pt-0"
                        required
                        :rules="requiredRules"
                    ></v-text-field>
                    <v-text-field
                        autocomplete="off"
                        :readonly="!isEditing"
                        label="Indirizzo"
                        class="pt-2"
                        v-model="indirizzo"
                        required
                        :rules="requiredRules"
                    ></v-text-field>
                </v-layout>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container 
                style="border: 1px solid LightGrey; border-radius: 5px;"
                class="py-4 mb-2"
                v-for="(elemento, index) in elementi"
                :key="index"
              >
                <v-layout column>
                  <v-textarea
                      autocomplete="off"
                      label="Descrizione"
                      :readonly="!isEditing"
                      v-model="elemento.descrizione"
                      hide-details
                      outlined
                      auto-grow
                      rows="2"
                      class="mb-3"
                  ></v-textarea>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                          autocomplete="off"
                          :readonly="!isEditing"
                          label="Quantità"
                          hide-details="auto"
                          min="1"
                          v-model="elemento.quantita"
                          required
                          :rules="numberRequiredRules"
                          type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                          dense
                          :readonly="!isEditing"
                          :items="array_unita_misura"
                          v-model="elemento.unita_misura"
                          required
                          outlined
                          hide-details="auto"
                          :rules="requiredRules"
                          label="Unità di misura"
                          class="mb-0 pt-2"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-layout>
              </v-container>

              <v-col cols="4" sm="4" offset-sm="4" class="pt-0 text-center"> 
                <v-btn 
                  color="primary" 
                  @click="addNewElement"
                  :disabled="!isEditing"
                  fab
                  small
                >
                  <v-icon small>fas fa-plus</v-icon>
                </v-btn> 
              </v-col>
            </v-tab-item>
            <v-tab-item>
              <v-container>
                <v-layout column>
                  <v-select
                      :readonly="!isEditing"
                      :items="lista_aspetti_esteriori"
                      v-model="aspetto_esteriore"
                      required
                      outlined
                      :rules="requiredRules"
                      hide-details="auto"
                      class="mb-3"
                      label="Aspetto Esteriore"
                  ></v-select>
                  <v-select
                      :readonly="!isEditing"
                      :items="lista_causali"
                      v-model="causale"
                      required
                      outlined
                      :rules="requiredRules"
                      hide-details="auto"
                      class="mb-3"
                      label="Causale"
                  ></v-select>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                          :readonly="!isEditing"
                          class="pt-1"
                          label="N. Colli"
                          v-model="numero_colli"
                          required
                          type="number"
                          :rules="numberRequiredRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                          :readonly="!isEditing"
                          class="pt-1"
                          label="Peso Totale"
                          v-model="peso_totale"
                          required
                          type="number"
                          :rules="numberRequiredRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-select
                      :readonly="!isEditing"
                      :items="lista_tipi_porti"
                      v-model="tipo_porto"
                      required
                      outlined
                      :rules="requiredRules"
                      hide-details="auto"
                      class="mb-3"
                      label="Porto"
                  ></v-select>
                  <v-text-field
                      :readonly="!isEditing"
                      class="pt-0"
                      label="Annotazioni"
                      v-model="annotazioni"
                  ></v-text-field>
                </v-layout>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
        <v-card-actions>
            <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0"> 
              <v-btn color="primary" width="100%" @click="submit" :disabled="isEditing && !formValid && loading" v-text="isEditing ? 'Salva' : 'Indietro'"></v-btn> 
            </v-col>
        </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import apiUtenti from '@/js/pages/utenti';
import apiStabilimenti from '@/js/pages/stabilimenti';
import apiDDT from '@/js/pages/ddt';
import { requiredRules, numberRequiredRules } from '@/js/validationRules';

export default {
  name: 'newDDT',
  props: ['idAttivita', 'id', 'isCreating', 'id_cliente', 'id_stabilimento'],
  data() {
    return {
      token: this.$cookies.get('token'),
      loading: false,
      isEditing: false,
      lista_tipi_attivita: ['Manutenzione', 'Posa', 'Controllo', 'Altro'],
      numero: 0,
      nome_destinatario: '',
      indirizzo: '',
      dettagli_testa: '',
      formValid: false,
      tab: 0,

      elementi: [{
        fake_num: 0,
        quantita: 1,
        unita_misura: '',
        descrizione: '',
      }],
      array_unita_misura: ['Kg', 'g', 'Scatole', 'Evacuatori', 'Pz'],

      causale: '',
      lista_causali: ['Vendita', 'Reso', 'Omaggio', 'Altro'],
      numero_colli: 1,
      peso_totale: 0,
      tipo_porto: '',
      lista_tipi_porti: ['Franco', 'Assegnato'],
      aspetto_esteriore: '',
      lista_aspetti_esteriori: ['Cassoni', 'Scatole', 'Sfuso', 'Vassoi', 'Bobine', 'A Vista'],
      annotazioni: '',

      requiredRules: requiredRules,
      numberRequiredRules: numberRequiredRules,
    }
  },
  async created() {
    if(!this.id) this.isEditing = true;
    if(this.id) {
      let DDT = await apiDDT.getOne(this.id);
      let utente = await apiUtenti.getUtenteFromId(DDT.id_cliente);
      let stabilimento = await apiStabilimenti.getOne(DDT.id_stabilimento);

      this.numero = DDT.numero;
      this.dettagli_testa = DDT.dettagli_testa;
      this.nome_destinatario = DDT.nome_destinatario || (utente.nominativo1 || '');
      this.indirizzo = DDT.indirizzo || (stabilimento.indirizzo || '');

      this.elementi = DDT.elementi || [];

      this.causale = DDT.causale || '';
      this.numero_colli = DDT.numero_colli || 1;
      this.peso_totale = DDT.peso_totale || 0;
      this.tipo_porto = DDT.tipo_porto || '';
      this.aspetto_esteriore = DDT.aspetto_esteriore || '';
      this.annotazioni = DDT.annotazioni || '';
    } else {
      if(this.id_cliente) {
        let utente = await apiUtenti.getUtenteFromId(this.id_cliente);
        if(utente) this.nome_destinatario = utente.nominativo1 || '';
      }
      if(this.id_stabilimento) {
        let stabilimento = await apiStabilimenti.getOne(this.id_stabilimento);
        if(stabilimento) this.indirizzo = stabilimento.indirizzo || '';
      }
    }
  },
  methods: {
    async submit(){
      if(this.isEditing){
        this.loading = true;
        if(
          this.$refs.form_data.validate() &&
          this.formValid
        ){
          let data = {
            id_attivita: this.idAttivita,
            dettagli_testa: this.dettagli_testa,
            nome_destinatario: this.nome_destinatario,
            indirizzo: this.indirizzo,

            elementi: this.elementi,

            causale: this.causale,
            numero_colli: this.numero_colli,
            peso_totale: this.peso_totale,
            tipo_porto: this.tipo_porto,
            aspetto_esteriore: this.aspetto_esteriore,
            annotazioni: this.annotazioni,
          };
          if(this.id) {
            data._id = this.id;
            await apiDDT.updateOne(this.token, data);
          } else await apiDDT.insertNew(this.token, data);
          this.$emit('DDTdone');
        }
        this.loading = false;
      } else this.$router.back();
    },
    async remove_ddt(){
      if(confirm("Eliminare il ddt?")){
        let data = { _id: this.id };
        await apiDDT.deleteOne(this.token, data);
        this.$emit('DDTdone');
      }
    },
    addNewElement() {
      this.elementi.push({
        fake_num: 0,
        quantita: 1,
        unita_misura: '',
        descrizione: '',
      });
    }
  },
}
</script>
